<template>
  <div class="container_on">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <div class="title_on">已成功开具<span>{{this.pagination.apply_count}}</span>张发票（合计{{this.pagination.apply_sum}}元）</div>

    <!-- 发票列表 -->
    <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
      <div class="list_on">
        <div class="list_item" v-for="(item,index) in dataList" :key="index">
          <div style="display: flex;justify-content: space-between;">
            <div>申请开票时间：{{item.create_time}}</div>
            <div>{{item.invoice_state==1?'待处理':(item.invoice_state==2?'已成功':'已拒绝')}}</div>
          </div>
          <div style="display: flex">
            <div>发票类型：{{item.invoice_type==1?'个人':'公司'}}</div><span>|</span>
            <div>发票抬头：{{item.invoice_header.organization}}</div>
          </div>
          <div style="display: flex">
            <div>发票介质：电子发票</div><span>|</span>
            <div>发票金额：{{item.invoice_amount}}</div>
          </div>
          <div style="display: flex;justify-content: space-between;maring-top:20px">
            <div class="error_message" v-if="item.invoice_state==3"> 异常：{{item.invoice_remark[0]}}</div>
            <div class="lookBtn" v-if="item.invoice_state==2" @click="lookBtn(item)">查看发票</div>
          </div>
        </div>
      </div>
    </vue-loadmore>
  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import { invoiceList } from "@/api/deal";
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      bgcShow: true,
      query: {
        page: 1,
        limit: 10
      },
      dataList: [],
      pagination: {

      },
      finished: false
    };
  },
  components: {
    cloneTab
  },
  created() {
    document.title = "开票记录";
    this.getList();
  },
  methods: {
    getList() {
      invoiceList(this.query).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.dataList =this.dataList.concat(res.data)
          this.pagination = res.pagination;
        }
      });
    },
     onRefresh(done) {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.fetch();

      done();
    },

    onLoad(done) {
      if (this.query.page <= 10) {
        this.fetch();
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },

    fetch() {
      this.query.page++;
      this.getList()
    },
    lookBtn(item) {
      ImagePreview(item.invoice_remark);
    }
  }
};
</script>
<style>
.container_on {
  padding-top: 40px;
  font-size: 30px;
}
.title_on {
  margin: 30px 0 20px 50px;
}
.title_on span {
  color: red;
}
.list_on {
  margin: 0 10px;
}
.list_item {
  padding: 24px 16px 70px;
  box-sizing: border-box;
  width: 720px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  margin: 20px auto;
  color: #5f5e5e;
  position: relative;
}
.list_item span {
  margin: 0 10px;
}
.list_item > div {
  margin: 10px 0;
}
.error_message {
  color: #ff391e;
}
.lookBtn {
  width: 190px;
  height: 65px;
  background: linear-gradient(180deg, #252324 0%, #3c3633 100%);
  box-shadow: 0px 4px 10px 0px rgba(184, 152, 100, 0.5);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  font-size: 26px;
  color: #f9e3be;
  text-align: center;
  line-height: 65px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>